#productfinder select {
    border-radius: 0;
    box-sizing: content-box;
    padding: 5px 0;
    height: 35px;
    margin-top: -9px;
    min-width: 35px;
    margin-left: 8px;
}

.pf_scrollbar {
    max-height: 200px;
    overflow-y: scroll;
}

#pf_filterList {
    clear: both;
    margin-top: 15px;
}

.pf_filterblock {
    background: #e6e6e6;
    border: solid 1px #adadad;
    padding: 5px 15px;
    padding-right: 5px;
    float: left;
    color: #000;
    margin-right: 5px;
    position: relative;
}

.pf_closeButton {
    cursor: pointer;
    margin-left: 12px;
}

.pf_main .dropdown-menu ul, .pf_navigation .dropdown-menu ul {
    padding-left: 5px;
}

.pf_main .dropdown-menu li, .pf_navigation .dropdown-menu li {
    list-style: none;
}

.pf_mr {
    margin-right: 2px;
}

.pf_hide-empty.empty {
    display: none;
}

.pf_hide {
    display: none;
}

.pf_property-disabled,
.facet-property.disabled {
    color: #e2e2e2;
}

.pf_property-disabled:hover,
.facet-property.disabled:hover {
    color: #e2e2e2;
    cursor: default;
}

.pf_infobox {
    background: black;
    border-radius: 10px;
    border: 0px;
    color: white;
    position: absolute;
    top: 35px;
    width: calc(100% + 100px);
    height: 100px;
    z-index: 99;
    text-align: center;
    padding: 10px;
}

.pf_relative {
    position: relative;
}

.pf_nav-tree {
    width:100%;
    padding: 8px;
}

.pf_nav-tree a {
    font-size: 14px;
}

.pf_text-large {
    font-size: 18px !important;
}
.pf_tree-toggler {
    font-size: 14px;
}

.pf_divider {
    border-top: 1px solid white;
}

.pf_li-blue {
    color: #32c5d2;
}

.pf_top-navigation a {
    color: white;
}

.pf_top-navigation a:hover {
    color: #32c5d2;
}

.pf_top-navigation a.selected {
    color: #32c5d2;
    font-weight: bold;
}

.pf_icon.active {
    color: #32c5d2;
}

.pf_facetItem {
    position: relative;
    margin-right: 10px;
    margin-bottom: 7px;

}

.pf_facetItem .dropdown-menu {
    padding: 10px;
    border-radius: 0;
    max-height: 285px;
    overflow: scroll;
}

.dropdown .btn .arrow {
    /*-webkit-transform: rotate(90deg);*/
    display: block;
    width: 20px;
    height: 15px;
    margin-left: 7px;
    font-size: 10px;
    float: right;
    margin-top: 5px;
}

.dropdown-menu .list {
    margin-bottom: 0;
}

.dropdown-menu .list label {
    cursor: pointer;
}

.pf_facetItem .btn.dropdown-toggle {
    border-radius: 0;
    padding-right: 0px;

}

.pf_facetItem .btn.apply {
    width: 100%;
}

#result-count {
    font-family: Roboto-bold, serif !important;
}

.pf_pre_list_row {
    margin-top: 50px;
    margin-bottom: -10px;
}

.pf_pre_list_row .fa {
    font-size: 17px;
}

.pf_pre_list_row .fa-th,  .pf_pre_list_row .fa-list {
    font-size: 22px;
}

.pf_pager_wrapper {
    text-align: center;
}

.product-item-block.col-sm-6,
.product-item-block.col-md-6,
.product-item-block.col-lg-4 {
    padding: 10px;
}
