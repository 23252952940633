@media screen and (max-width: 699px) {
    #solar-values {
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .small-header #solar-values {
        display: none;
    }
}

#solar-values iframe {
    display: block;
    width: 160px;
    height: 35px;
    overflow: hidden;
    border: none;
    pointer-events: none;
}
