table {
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0
}

td, th {
  padding: 0
}

th {
  text-align: left
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd
}

.table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > td {
  border-top: 0
}

.table > tbody + tbody {
  border-top: 2px solid #ddd
}

.table .table {
  background-color: #fff
}

.table-condensed > thead > tr > th, .table-condensed > tbody > tr > th, .table-condensed > tfoot > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > td {
  padding: 5px
}

.table-bordered {
  border: 1px solid #ddd
}

.table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
  border: 1px solid #ddd
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  border-bottom-width: 2px
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5
}

table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column
}

table td[class*="col-"], table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell
}

.table > thead > tr > td.active, .table > tbody > tr > td.active, .table > tfoot > tr > td.active, .table > thead > tr > th.active, .table > tbody > tr > th.active, .table > tfoot > tr > th.active, .table > thead > tr.active > td, .table > tbody > tr.active > td, .table > tfoot > tr.active > td, .table > thead > tr.active > th, .table > tbody > tr.active > th, .table > tfoot > tr.active > th {
  background-color: #f5f5f5
}

.table-hover > tbody > tr > td.active:hover, .table-hover > tbody > tr > th.active:hover, .table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8
}

.table > thead > tr > td.success, .table > tbody > tr > td.success, .table > tfoot > tr > td.success, .table > thead > tr > th.success, .table > tbody > tr > th.success, .table > tfoot > tr > th.success, .table > thead > tr.success > td, .table > tbody > tr.success > td, .table > tfoot > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr.success > th, .table > tfoot > tr.success > th {
  background-color: #dff0d8
}

.table-hover > tbody > tr > td.success:hover, .table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6
}

.table > thead > tr > td.info, .table > tbody > tr > td.info, .table > tfoot > tr > td.info, .table > thead > tr > th.info, .table > tbody > tr > th.info, .table > tfoot > tr > th.info, .table > thead > tr.info > td, .table > tbody > tr.info > td, .table > tfoot > tr.info > td, .table > thead > tr.info > th, .table > tbody > tr.info > th, .table > tfoot > tr.info > th {
  background-color: #d9edf7
}

.table-hover > tbody > tr > td.info:hover, .table-hover > tbody > tr > th.info:hover, .table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr:hover > .info, .table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3
}

.table > thead > tr > td.warning, .table > tbody > tr > td.warning, .table > tfoot > tr > td.warning, .table > thead > tr > th.warning, .table > tbody > tr > th.warning, .table > tfoot > tr > th.warning, .table > thead > tr.warning > td, .table > tbody > tr.warning > td, .table > tfoot > tr.warning > td, .table > thead > tr.warning > th, .table > tbody > tr.warning > th, .table > tfoot > tr.warning > th {
  background-color: #fcf8e3
}

.table-hover > tbody > tr > td.warning:hover, .table-hover > tbody > tr > th.warning:hover, .table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr:hover > .warning, .table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc
}

.table > thead > tr > td.danger, .table > tbody > tr > td.danger, .table > tfoot > tr > td.danger, .table > thead > tr > th.danger, .table > tbody > tr > th.danger, .table > tfoot > tr > th.danger, .table > thead > tr.danger > td, .table > tbody > tr.danger > td, .table > tfoot > tr.danger > td, .table > thead > tr.danger > th, .table > tbody > tr.danger > th, .table > tfoot > tr.danger > th {
  background-color: #f2dede
}

.table-hover > tbody > tr > td.danger:hover, .table-hover > tbody > tr > th.danger:hover, .table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr:hover > .danger, .table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc
}

.table-responsive {
  overflow-x: auto;
  min-height: .01%
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd
  }

  .table-responsive > .table {
    margin-bottom: 0
  }

  .table-responsive > .table > thead > tr > th, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap
  }

  .table-responsive > .table-bordered {
    border: 0
  }

  .table-responsive > .table-bordered > thead > tr > th:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0
  }

  .table-responsive > .table-bordered > thead > tr > th:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0
  }

  .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tfoot > tr:last-child > th, .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0
  }
}

.panel > .table, .panel > .table-responsive > .table, .panel > .panel-collapse > .table {
  margin-bottom: 0
}

.panel > .table caption, .panel > .table-responsive > .table caption, .panel > .panel-collapse > .table caption {
  padding-left: 15px;
  padding-right: 15px
}

.panel > .table:first-child, .panel > .table-responsive:first-child > .table:first-child {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px
}

.panel > .table:first-child > thead:first-child > tr:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px
}

.panel > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child, .panel > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:first-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: 3px
}

.panel > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child, .panel > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child, .panel > .table:first-child > tbody:first-child > tr:first-child th:last-child, .panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 3px
}

.panel > .table:last-child, .panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px
}

.panel > .table:last-child > tbody:last-child > tr:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 3px
}

.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child, .panel > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child, .panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child, .panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 3px
}

.panel > .panel-body + .table, .panel > .panel-body + .table-responsive, .panel > .table + .panel-body, .panel > .table-responsive + .panel-body {
  border-top: 1px solid #ddd
}

.panel > .table > tbody:first-child > tr:first-child th, .panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0
}

.panel > .table-bordered, .panel > .table-responsive > .table-bordered {
  border: 0
}

.panel > .table-bordered > thead > tr > th:first-child, .panel > .table-responsive > .table-bordered > thead > tr > th:first-child, .panel > .table-bordered > tbody > tr > th:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:first-child, .panel > .table-bordered > tfoot > tr > th:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child, .panel > .table-bordered > thead > tr > td:first-child, .panel > .table-responsive > .table-bordered > thead > tr > td:first-child, .panel > .table-bordered > tbody > tr > td:first-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:first-child, .panel > .table-bordered > tfoot > tr > td:first-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0
}

.panel > .table-bordered > thead > tr > th:last-child, .panel > .table-responsive > .table-bordered > thead > tr > th:last-child, .panel > .table-bordered > tbody > tr > th:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > th:last-child, .panel > .table-bordered > tfoot > tr > th:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child, .panel > .table-bordered > thead > tr > td:last-child, .panel > .table-responsive > .table-bordered > thead > tr > td:last-child, .panel > .table-bordered > tbody > tr > td:last-child, .panel > .table-responsive > .table-bordered > tbody > tr > td:last-child, .panel > .table-bordered > tfoot > tr > td:last-child, .panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0
}

.panel > .table-bordered > thead > tr:first-child > td, .panel > .table-responsive > .table-bordered > thead > tr:first-child > td, .panel > .table-bordered > tbody > tr:first-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > td, .panel > .table-bordered > thead > tr:first-child > th, .panel > .table-responsive > .table-bordered > thead > tr:first-child > th, .panel > .table-bordered > tbody > tr:first-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
  border-bottom: 0
}

.panel > .table-bordered > tbody > tr:last-child > td, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > td, .panel > .table-bordered > tfoot > tr:last-child > td, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td, .panel > .table-bordered > tbody > tr:last-child > th, .panel > .table-responsive > .table-bordered > tbody > tr:last-child > th, .panel > .table-bordered > tfoot > tr:last-child > th, .panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0
}

.panel > .table-responsive {
  border: 0;
  margin-bottom: 0
}

.table-cart form {
  display: inline-block;
  margin-top: 6px
}

.table-cart input[type=checkbox] {
  display: none
}

.table-cart input[type=checkbox] + label {
  cursor: pointer
}

.table-cart input[type=checkbox] + label span {
  display: block;
  width: 16px;
  height: 16px;
  background: #fff;
  border: 1px solid #7e8383;
  transition: background .2s linear
}

.table-cart input[type=checkbox]:checked + label span {
  background-color: #7e8383
}

.indented-table {
  padding: 20px 0 0;
}

.indented-table h2, .indented-table .afriso_h2 {
  text-transform: uppercase;
  margin-bottom: 16px
}

.indented-table .table-responsive {
  padding-left: 68px;
  margin-bottom: 14px
}

@media screen and (max-width: 992px) {
  .indented-table .table-responsive {
    padding-left: 0
  }
}

.indented-table h4 {
  padding-left: 83px;
  font-size: 16px;
  line-height: 32px;
  font-weight: 700;
  text-transform: none;
  margin-bottom: 11px;
  color: #7e8383;
}

@media screen and (max-width: 910px) {
  .indented-table h4 {
    padding-left: 0
  }
}

.indented-table .table.simple {
  margin-bottom: 0
}

.indented-table .table.simple thead tr th {
  padding: 11px
}

.indented-table .table.simple tbody tr .col1 {
  border-bottom: 1px solid #e4eef8
}

.indented-table .table.simple tbody tr td {
  padding: 18px 16px
}

.indented-table .table.simple tr th, .indented-table .table.simple tr td {
  font-size: 16px
}

.indented-table .table.simple tr .col1 {
  width: 373px
}

.indented-table .table.simple tr .col2 {
  width: 303px
}

.indented-table .table.simple tr .col3 {
  width: 103px
}

.indented-table .table td, .indented-table .table th {
  font-size: 16px;
  line-height: 15px;
  color: #687074;
  font-weight: 400;
  vertical-align: middle;
  text-align: left
}

@media screen and (max-width: 1200px) {
  .indented-table .table td:nth-child(2), .indented-table .table th:nth-child(2) {
    min-width: 100%
  }
}

.indented-table .table td strong, .indented-table .table th strong {
  font-weight: 700
}

.indented-table .table .pin {
  width: 19px;
  height: 20px;
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -369px -71px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer
}

.indented-table .table .pin.selected {
  background-position: -349px -71px
}

.indented-table .table tr th {
  vertical-align: top;
  padding: 14px 8px 0
}

.indented-table .table tr td, .indented-table .table tr th {
  color: #687074;
  border: none;
  font-size: 14px
}

.indented-table .table tr td {
  width: auto;
}

.indented-table .table tr .col4 tbody tr td:first-child {
  padding-left: 0;
  text-align: left
}

.indented-table .table tbody .no-padding td {
  padding: 0 8px
}

.indented-table .table tbody tr {
  height: 26px
}

.indented-table .table tbody tr:first-child td {
  /*border-bottom: 1px solid #e4eef8*/
}

.indented-table .table tbody tr td {
  border-bottom: 1px solid #e4eef8;
  vertical-align: middle;
  padding: 5px 9px
}

.indented-table .table td {
  border-top: none;
  border-bottom: 1px solid #e4eef8
}

@media screen and (max-width: 1200px) {
  .indented-table .table td:first-child {
    min-width: 100%
  }
}

.indented-table .table > thead > tr > th {
  border-bottom-width: 1px;
  border-color: #c4c4c4
}

.indented-table .table > thead > tr > th, .indented-table .table > tbody > tr > th, .indented-table .table > tfoot > tr > th, .indented-table .table > thead > tr > td, .indented-table .table > tbody > tr > td, .indented-table .table > tfoot > tr > td {
  padding: 6px 10px
}

.table-responsive > .table > thead > tr > th, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > td {
  white-space: normal
}

.tabs .table-responsive {
  width: 100%
}

.tabs .table-responsive .table > thead > tr > th, .tabs .table-responsive .table > tbody > tr > th, .tabs .table-responsive .table > tfoot > tr > th {
  line-height: 30px
}

.tabs .table-responsive .table > thead > tr > td, .tabs .table-responsive .table > tbody > tr > td, .tabs .table-responsive .table > tfoot > tr > td {
  line-height: 20px;
  padding: 16px 8px
}

.tabs .table-responsive .table-downloads {
  margin-top: 19px
}

@media screen and (min-width: 650px) {
  .tabs .table-responsive .table-downloads .col1 {
    width: 464px
  }
}

@media screen and (min-width: 650px) {
  .tabs .table-responsive .table-downloads .col2 {
    width: 316px
  }
}

@media screen and (min-width: 650px) {
  .tabs .table-responsive .table-downloads .col3 {
    width: 103px
  }
}

@media screen and (min-width: 650px) {
  .tabs .table-responsive .table-downloads .col4 {
    width: 143px
  }
}

.tabs .table-responsive .table-downloads .col4 .select-wrapper {
  min-width: 113px;
  max-width: 113px
}

@media screen and (min-width: 650px) {
  .tabs .table-responsive .table-downloads .col5 {
    width: auto
  }
}

.tabs .table-responsive .table-search {
  margin-top: 0
}

.tabs .table-responsive .table-search > thead > tr > th {
  line-height: 21px;
  padding: 8px 8px 15px
}

.tabs .table-responsive .table-search > tbody > tr > td {
  padding: 12px 8px
}

@media screen and (min-width: 650px) {
  .tabs .table-responsive .table-search .col1 {
    width: 560px
  }
}

@media screen and (min-width: 650px) {
  .tabs .table-responsive .table-search .col2 {
    width: 220px
  }
}
.afriso-partner-world .table #searchResults tr td:first-child {
  background-color: #e5e5e5;
  text-align: center;
}

.table-downloads thead th {
  font-weight: 700
}

.table-downloads td, .table-downloads th {
  font-size: 16px;
  font-weight: 400;
  color: #687074;
  vertical-align: middle !important
}

.table-downloads tbody tr td:first-child {
  font-weight: 700
}

.table-cart {
  padding: 23px 0 50px
}

.table-cart h1 {
  margin-bottom: 41px
}

.table-cart .send-inquiry {
  text-decoration: underline;
  color: #548bcc
}

@media screen and (max-width: 1200px) {
  .table-cart .send-inquiry {
    padding-right: 160px;
  }
}

@media screen and (max-width: 992px) {
  .table-cart .send-inquiry {
    padding-right: 120px;
  }
}

.table-cart .table > thead > tr > th, .table-cart .table > tbody > tr > th, .table-cart .table > tfoot > tr > th, .table-cart .table > thead > tr > td, .table-cart .table > tbody > tr > td, .table-cart .table > tfoot > tr > td {
  padding: 14px 8px
}

@media screen and (min-width: 1010px) {
  .table-cart .table-responsive {
    margin-left: -15px;
    margin-right: -15px
  }
}

.table-cart .table-responsive .table tr th, .table-cart .table-responsive .table tr td {
  font-size: 14px
}

.table-cart .table {
  margin-bottom: 0
}

.table-cart .table .col1 {
  width: 381px;
}

@media screen and (max-width: 885px) {
  .table-cart .table .col1 {
    min-width: 270px
  }
}

.table-cart .table .col2 {
  text-align: center;
  width: 115px
}

.table-cart .table .col3 {
  text-align: center;
  width: 123px
}

.table-cart .table .col4 {
  text-align: center;
  width: 113px
}

.table-cart .table .col4 a {
  display: block;
  font-size: 14px
}

.table-cart .table .col4 a.delete-all, a.clear-all-filter {
  color: #b6101d;
  margin-top: 9px;
  text-decoration: underline
}

a.clear-all-filter:hover {
  text-decoration: none;
}

.table-cart .table .col5 {
  width: 99px;
  padding-left: 15px
}

.table-cart .table thead th {
  font-weight: 700;
  line-height: 15px;
  border-bottom: 1px solid #c4c4c4
}

.table-cart .table thead th:first-child {
  padding-left: 98px
}

.table-cart .table thead th:last-child {
  text-align: center
}

.table-cart .table td, .table-cart .table th {
  color: #687074;
  vertical-align: middle
}

.table-cart .table tbody tr td {
  padding: 19px 8px;
  border-top: 1px solid #e4eef8
}

.table-cart .table tbody tr:last-child .col5 form {
  margin-top: 0
}

.table-cart .table tbody td {
  font-weight: 400;
  line-height: 18px
}

.table-cart .table tbody td:first-child {
  font-weight: 700;
  position: relative;
  padding-left: 15px
}

.table-cart .table tbody td figure {
  float: left;
  width: 68px;
  margin-right: 13px
}

.table-cart .table tbody td figure + strong {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%)
}

.table-cart .table tbody td figure img {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.table-cart .table tbody td .remove-from-cart {
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -121px -60px;
  width: 16px;
  max-width: 16px;
  max-height: 16px;
  height: 16px;
  border: none;
  box-shadow: none;
  padding: 0;
  display: block;
  margin-left: auto;
  margin-right: auto
}

.table-cart .table tbody td .remove-from-cart:focus {
  outline: none
}

@media screen and (max-width: 767px) {
  .table-cart #wishlist-email-Link,
  .table-cart .table-responsive .table .col4 a.delete-all{
    width: 100%;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    color: #ffff;
    background-color: #286090;
    border-color: #204d74;
    background-image: -webkit-linear-gradient(top,#337ab7 0,#265a88 100%);
    background-image: -o-linear-gradient(top,#337ab7 0,#265a88 100%);
    background-image: -webkit-gradient(linear,left top,left bottom,from(#337ab7),to(#265a88));
    background-image: linear-gradient(to bottom,#337ab7 0,#265a88 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff265a88', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    background-repeat: repeat-x;
    border-radius: 4px;
  }
}
@media screen and (max-width: 767px) {
  #accordion-info-product-variant .panel-collapse .panel-body,
  .table-cart .table-responsive
  {
    width: 100%;
    overflow-y: auto;
    _overflow: auto;
    margin: 0 0 1em;
    padding-bottom: 0px;
    margin-bottom: 30px;
  }
  #accordion-info-product-variant .panel-collapse .panel-body::-webkit-scrollbar,
  .table-cart .table-responsive::-webkit-scrollbar
  {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
  }
  #accordion-info-product-variant .panel-collapse .panel-body::-webkit-scrollbar-thumb,
  .table-cart .table-responsive::-webkit-scrollbar
  {
    border-radius: 8px;
    border: 3px solid #fff;
    background-color: rgba(0, 0, 0, .3);
  }
}

.table-results {
  padding: 20px 0 30px
}

.table-results h2, .table-results .afriso_h2 {
  text-transform: uppercase;
  margin-bottom: 32px
}

.table-results .table thead tr th {
  font-weight: 700;
  line-height: 30px;
  padding: 9px;
  border-bottom: 1px solid #c4c4c4
}

.table-results .table thead tr th:first-child {
  padding-left: 119px
}

.table-results .table tbody tr td {
  padding: 5px;
  border-top: 1px solid #e4eef8
}

.table-results .table tbody tr td p {
  padding-top: 12px;
  font-size: 16px;
  color: #687074;
  line-height: 20px;
  margin-bottom: 0
}

.table-results .table tbody tr td p a {
  color: #2d71c1;
  font-size: 16px;
  text-decoration: none
}

.table-results .table tbody tr td figure {
  width: 100px;
  height: 100px;
  display: inline-block;
  margin-right: 12px
}

.table-results .table tbody tr td figure img {
  text-align: center
}

.table-results .table tbody tr td:first-child {
  font-weight: 700
}

.table-results .table tbody tr td:first-child p {
  display: inline-block;
  vertical-align: top
}

.table-results .table tbody tr td ul {
  padding-top: 12px;
  margin-bottom: 0;
  padding-left: 20px
}

.table-results .table tbody tr td ul li {
  position: relative
}

.table-results .table tbody tr td ul li:before {
  content: '■';
  position: absolute;
  left: -17px;
  display: block;
  top: -4px;
  font-size: 16px;
  color: #687074
}

.table-results .table tbody tr td ul li p {
  font-size: 16px;
  padding-top: 0;
  margin-bottom: 0
}

.table-results .table .col2 {
  width: 268px
}

.table-results .table .col3 {
  width: 332px
}

.table-results .table .col3 p {
  padding-top: 0;
  padding-left: 6px
}

.table-results .table .col3 p:first-child {
  padding-top: 12px
}

.table-results .table .col4 {
  width: 177px
}

.table-responsive .table th, .table-responsive .table td {
  font-size: 16px;
  color: #687074
}

.text-left-map-right .table-responsive {
  width: 100%;
  height: 698px;
  position: relative;
  margin-right: 10px
}

@media screen and (max-width: 986px) {
  .text-left-map-right .table-responsive {
    margin-bottom: 20px
  }
}

.text-left-map-right .table-responsive .table tr td {
  font-size: 16px;
  color: #687074;
  padding: 16px 8px;
  border: none
}

.text-left-map-right .table-responsive .table tr td ul {
  margin-bottom: 0;
  padding-left: 20px;
  padding-left: 14px
}

.text-left-map-right .table-responsive .table tr td ul li {
  position: relative
}

.text-left-map-right .table-responsive .table tr td ul li:before {
  content: '■';
  position: absolute;
  left: -15px;
  display: block;
  top: -4px;
  font-size: 16px;
  color: #687074
}

.text-left-map-right .table-responsive .table tr td ul li p {
  font-size: 16px;
  padding-top: 0;
  margin-bottom: 0
}

.text-left-map-right .table-responsive .table tr td p {
  line-height: 20px;
  margin-bottom: 0
}

.text-left-map-right .table-responsive .table tr td .circle {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background: #2866B9;
  position: relative;
  margin: 4px 0
}

.text-left-map-right .table-responsive .table tr td .circle + p {
  font-size: 12px;
  color: #7e8383
}

.text-left-map-right .table-responsive .table tr td .circle span {
  color: #fff;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center
}

.text-left-map-right .table-responsive .table tr td.col1 {
  padding: 16px 8px 16px 16px
}

@media screen and (min-width: 986px) {
  .text-left-map-right .table-responsive .col1 {
    width: 74px
  }
}

.text-left-map-right .table-responsive .col2 p {
  margin-bottom: 0;
  line-height: 20px
}

.text-left-map-right .table-responsive .col2 p:first-child {
  margin-bottom: 6px;
  line-height: 23px
}

.text-left-map-right .table-responsive .col2 p:first-child + p {
  margin-bottom: 6px;
  line-height: 23px
}

@media screen and (min-width: 986px) {
  .text-left-map-right .table-responsive .col2 {
    width: 259px
  }
}

.indented-table .container .table thead th {
  font-weight: bold;
  color: #687074;
  border: none;
  font-size: 14px;
}

.article-content .ezrichtext-field .table-responsive .table td {
  width: 20%;
  text-align: center;
}

.ps-container table {
  overflow: scroll;
}

.content-block .article-content > .article-body > .ezrichtext-field > .table-responsive > table.table {
  margin-bottom: 0px;
}

.content-block .article-content > .article-body > .ezrichtext-field > .table-responsive > table.table > tbody > tr > td {
  border: none;
}

.content-block .article-content > .article-body > .ezrichtext-field > .table-responsive > table.table > tbody > tr > td:first-child {
  padding: 0 8px 0 0;
}

.content-block .article-content > .article-body > .ezrichtext-field > .table-responsive > table.table > tbody > tr > td:not(:first-child) {
  padding: 0 0 0 8px;
}

.content-block .article-content > .article-body > .ezrichtext-field > .table-responsive > table.table > tbody > tr:nth-child(2) > td > p {
  margin-top: 10px;
}

.content-block .article-content > .article-body > .ezrichtext-field > .table-responsive > table.table.table-search > tbody > tr > td,
.content-block .article-content > .article-body > .ezrichtext-field > .table-responsive > table.table.table-downloads > tbody > tr > td {
  border-top: 1px solid #ddd;
}

.indented-table .table tr .onstock {
  color: #0065BF;
  font-weight: bold;
}

.partner-result .table .tooltip.bottom {
  margin-top: 10px;
}

.pd-table-legend-prefix {
  color: #0065BF;
}

.table-legend {
  text-align: right;
}

.table-legend p {
  font-size: 12px;
  font-family: Helvetica, Arial, sans-serif;
}

.downloads .table-downloads .download-arrow-image {
  margin-left: 40%;
  margin-right: 25%;
}

.table-downloads .col5 {
  width: 15%;
}

.table-downloads .download-arrow-image {
  margin-left: 40%;
}

.table-downloads .select-wrapper select {
  min-width: 100px;
}

.table-downloads .select-wrapper {
  padding-right: 0px;
}

.table-mobile * {
  border: none !important;
}

@media only screen and (max-width: 40em) {
  .table-mobile thead th:not(:first-child) {
    display: none;
  }

  .table-mobile td, th {
    display: block;
  }

  .table-downloads .table-mobile td, th {
    display: table-cell;
  }

  .table-mobile td[data-th]:before  {
    content: attr(data-th);
  }
}

.variants_table .col2 {
  text-align: left !important;
}

.variants_table .col3 {
  text-align: center !important;
}

table > thead > tr > th, table > tbody > tr > th, table > tfoot > tr > th, table > thead > tr > td, table > tbody > tr > td, table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #ddd;
}