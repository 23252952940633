.content-block .article-content > .article-image > img {
    display: block;
    max-width: 100%;
    height: auto;
}

.four-col-img-text-teaser .same-height,
.three-col-tabs-content .same-height {
    min-height: 480px;
}

.four-col-img-text-teaser .same-height {
    text-align: left;
    margin-bottom: 41px;
}

.img-teaser-2 figure {
    width: 100%;
    overflow: hidden;
}

.img-teaser-2 figure img {
    max-width: 100%;
}

@media screen and (max-width: 1024px) {
    .four-col-img-text-teaser .same-height {
        min-height: 352px;
    }
}

@media screen and (max-width: 768px) {
    .four-col-img-text-teaser .same-height figure img {
        max-height: 280px;
    }
}

/* Bild Text Teaser V2 */
.three-col-img-text-teaser-1 figure{
    width: 50%;
    height: auto;
    float: left;
}

.three-col-img-text-teaser-1 h3{
    width: 50%;
    float: left;
    font-size: 16px;
    margin-top: 20px;
}

.three-col-img-text-teaser-1 .same-height{
    min-height: 90px;
    float:left;
    padding: 5px;
    border: 1px solid #c4c4c4;
}

.three-col-img-text-teaser-1 .next-btn.btn-bottom{
    left: 70%;
    bottom: 20px;
}

.three-col-teaser-product-1 img{
    margin: 0 auto 15px auto;
    position: relative;
    max-width: 100%;
    height: 240px;
}

.img-fullwidth img {
    width: 100%;
}

.tech-data-img {
    border: 1px solid #bbbbbb;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-top: 10px;
}

.tech-data-img img {
    position: absolute;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
}

.tech-data-popup {
    display: none;
}

.tech-data-popup td {
    text-align: center;
}

.tech-data-popup tr.border_top:first-child {
    height: 30px;
}

.tech-data-popup table .border_top td {
    border-color: #bbbbbb;
}

.tech-data-popup tr.border_top:first-of-type td {
    border: none;
}

.tech-data-popup img {
    width: 100%;
    background: white;
    padding: 10px;
    margin-bottom: 20px;
}

.afriso-simple-slider-image{
    padding: 25px 0;
}

.product-pictograms {
    margin-top: 25px;
}

.product-pictograms img {
    margin-right: 10px;
}

footer .bottom-footer .footer-elements .earth {
    background: url(/assets/build/images/bgi/keyvisuals-footer-erde.png) no-repeat;
}

footer .bottom-footer .footer-elements .fire {
    background: url(/assets/build/images/bgi/keyvisuals-footer-feuer.png) no-repeat;
}

footer .bottom-footer .footer-elements .air {
    background: url(/assets/build/images/bgi/keyvisuals-footer-himmel.png) no-repeat;
}

footer .bottom-footer .footer-elements .water {
    background: url(/assets/build/images/bgi/keyvisuals-footer-wasser.png) no-repeat;
}

.downloads .table-downloads .download-arrow-image {
    margin-left: 40%;
    margin-right: 25%;
}

.download-center input[type=submit] {
    border: none;
    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -9px;
    width: 18px;
    height: 18px;
    background: url(../images/bgi/AFRISO_Icons_Sprite.png) -274px -71px, center;
}

.table-downloads .download-arrow-image {
    margin-left: 40%;
}

.thumbnail-video-full i {
    width: 186px;
    height: 136px;
    position: absolute;
    background-image: url('../images/slide-play.png');
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.7;
    z-index: 1;
    top: 35%;
    left: 25%;
    background-size: 186px;
}

.afriso-right-arrow-blue, .afriso-left-arrow-blue {
    background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -317px -69px;
    display: inline-block;
    width: 12px;
    height: 30px;
}

.afriso-right-arrow-blue {
    background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -317px -99px;
}

.up-btn {
    height: 36px;
    width: 36px;
    display: block;
    background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -387px -35px !important;
    transform: rotate(-90deg);
    cursor: pointer;
}

.header .language-wrapper a.language {
    background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -177px -35px;
}

.header .language-wrapper a.language:hover {
    text-decoration: none;
    background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -142px -35px
}

.tooltip.top .tooltip-arrow {
    border: none;
    background: url(/assets/build/images/bgi/AFRISO_Icons_Sprite2.png) no-repeat -46px -5px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    width: 12px;
    height: 11px;
    top: 28px;
}

.teaser_title_img_intro_price {
    position: relative;
    margin-top: 30px;
    border: 1px solid lightgrey;
}
.teaser_title_img_intro_price .image-teaser, .teaser_title_img_intro_price .info, .teaser_title_img_intro_price .intro {
    float:left;
}

.teaser_title_img_intro_price .info, .teaser_title_img_intro_price .intro {
    margin-top:30px;
}

.teaser_title_img_intro_price .intro {
    width: 50%;
}

.teaser_title_img_intro_price .next-btn.btn-bottom,
.three-col-teaser-product-1 .next-btn.btn-bottom,
.three-col-img-text-teaser-2 .next-btn.btn-bottom{
    bottom: 6px;
}

.product-section .product-finder-list-wrapper .three-col-img-text-teaser {
    margin-left: -15px;
    margin-right: -15px;
}

.three-col-tabs-content .same-height h3,
.four-col-img-text-teaser .same-height h3,
.three-col-img-text-teaser .same-height h3 {
    padding-left: 12px;
    padding-right: 12px;
}

.three-col-tabs-content .same-height h3,
.four-col-img-text-teaser .same-height h3,
.three-col-img-text-teaser .same-height h3,
.three-col-icon-text-teaser .text-center h3 {
    max-height: 84px;
}

.four-col-img-text-teaser .same-height h3 {
    margin-bottom: 10px;
}

.three-col-tabs-content .same-height .ezrichtext-field,
.four-col-img-text-teaser .same-height .ezrichtext-field,
.three-col-img-text-teaser .same-height .ezrichtext-field {
    padding-left: 12px;
    padding-right: 12px;
}

.image-placeholder .image,
.image-placeholder a,
.image-placeholder img {
    width: 100%;
    height: 100%;
}

.image-placeholder {
    height: 0;
    padding-top: 50%;
    position: relative;
    background: #f1f1f1;
}

.image-placeholder .image {
    position: absolute;
    background: #fff;
    display: flex !important;
    align-items: center;
    top: 0;
}

.image-placeholder img {
    object-fit: cover;
}

.img-container img {
    width: 100%;
}

.image-width-pct-70 img {
    max-width: 70%;
}

.image-height-pct-90 img {
    max-height: 90%;
}

.image-height-pct-100 img {
    max-height: 100%;
}

.ix-bgc-light-blue .three-col-img-text-teaser .same-height.border {
    padding: 0;
}

.ix-bgc-light-blue .three-col-img-text-teaser .same-height.border figure {
    min-height: unset;
}

.ix-bgc-light-blue .three-col-img-text-teaser .same-height.border figure img {
    position: unset;
    top: unset;
    left: unset;
    transform: none;
    margin-bottom: 25px;
}

.three-col-img-text-teaser {
    padding-top: 27px;
}

@media screen and (max-width: 768px) {
    .three-col-img-text-teaser {
        padding-top: 9px;
    }
}

.size-i320,
.size-i480,
.size-i770,
.size-i1140,
.size-i1920,
.size-x_small,
.size-small,
.size-medium,
.size-large,
.size-x_large,
.size-mb0 img {
    max-width: 100%;
}

.size {
    max-width: 300px;
}

.size-x_small, .size-mb0 {
    max-width: 200px;
}

.size-small {
    max-width: 330px;
}

.size-medium {
    max-width: 630px;
}

.size-large {
    max-width: 300px;
}

.size-x_large, .size-i1920 {
    max-width: 1920px;
}

.size-i250 {
    max-width: 250px;
}

.size-i320 {
    max-width: 320px;
}

.size-i480 {
    max-width: 630px;
}

.size-i770 {
    max-width: 770px;
}

.size-i1140 {
    max-width: 1140px;
}

.drawings-table td {
    padding: 0 0 0 18px;
}

.fancybox-content {
    border-radius: 9px;
}
