@charset "UTF-8";
//IMPORTANT: only add imports to this file
$fa-font-path: '../vendor/components-font-awesome/fonts';

@import "~swiper/swiper";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import 'bootstrap_variables';
@import 'bootstrap';
@import "../../../../../../../vendor/ix/product-connector/Resources/public/vendor/bootstrap-select/dist/css/bootstrap-select.css";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";

@import '../../../../../../../vendor/ix/product-connector/Resources/public/vendor/bootstrap/css/bootstrap-glyphicons.css';
@import '../../../../../../../src/ix/Bundle/AfrisoBundle/Resources/assets/css/productfinder.css';
@import '../../../../../../../src/ix/Bundle/AfrisoBundle/Resources/assets/css/layout.css';
@import '../../../../../../../src/ix/Bundle/AfrisoBundle/Resources/assets/images/fancybox/helpers/jquery.fancybox-thumbs.css';

@import 'footer';
@import 'variables';
@import 'typography';
@import 'ngmore';
@import 'main';
@import "images";
@import "sliders";
@import "product_finder";
@import 'afriso_style';
@import 'teasers';
@import 'tech_data';
@import 'tables';
@import 'fixes';
