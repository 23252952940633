.text-teaser .content {
  padding-top: 0px;
}

.text-teaser .content p {
  margin-bottom: 0px;
}

.three-col-icon-text-teaser .text-center {
  height: 376px;
}

.four-col-img-text-teaser .same-height,
.three-col-tabs-content .same-height {
  min-height: 480px;
}

h3, .h3, .four-col-img-text-teaser .same-height h3 {
  font-size: 26px;
}

.three-col-tabs-content .same-height h3,
.four-col-img-text-teaser .same-height h3,
.three-col-img-text-teaser .same-height h3 {
  padding-left: 12px;
  padding-right: 12px;
}

.three-col-tabs-content .same-height h3,
.four-col-img-text-teaser .same-height h3,
.three-col-img-text-teaser .same-height h3,
.three-col-icon-text-teaser .text-center h3 {
  max-height: 84px;
}

.four-col-img-text-teaser .same-height h3 {
  margin-bottom: 10px;
}

.three-col-tabs-content .same-height .ezrichtext-field,
.four-col-img-text-teaser .same-height .ezrichtext-field,
.three-col-img-text-teaser .same-height .ezrichtext-field {
  padding-left: 12px;
  padding-right: 12px;
}

.three-col-tabs-content .same-height .ezrichtext-field p {
  padding-left: 0;
  padding-right: 0;
}

.four-col-img-text-teaser .same-height {
  text-align: left;
  margin-bottom: 41px;
}

.img-teaser-2 figure {
  width: 100%;
  overflow: hidden;
}

.img-teaser-2 figure img {
  max-width: 100%;
}

@media screen and (max-width: 1024px) {
  .four-col-img-text-teaser .same-height {
    min-height: 352px;
  }
}

@media screen and (max-width: 768px) {
  .four-col-img-text-teaser .same-height figure img {
    max-height: 280px;
  }
}

.three-col-img-text-teaser-1 figure{
  width: 50%;
  height: auto;
  float: left;
}

.three-col-img-text-teaser-1 h3{
  width: 50%;
  float: left;
  font-size: 16px;
  margin-top: 20px;
}

.three-col-img-text-teaser-1 .same-height{
  min-height: 90px;
  float:left;
  padding: 5px;
  border: 1px solid #c4c4c4;
}

.three-col-img-text-teaser-1 .next-btn.btn-bottom{
  left: 70%;
  bottom: 20px;
}

.three-col-teaser-product-1 {
  margin-bottom: 25px;
  margin-top: 25px;
}

.three-col-teaser-product-1 img{
  margin: 0 auto 15px auto;
  position: relative;
  max-width: 100%;
  height: 240px;
}

.three-col-teaser-product-1 .same-height {
  min-height: 410px;
  padding: 31px 5px 10px;
  text-align: center;
  margin-bottom: 30px;
  border: 1px solid #c4c4c4;
  position: relative;
}

.three-col-teaser-product-1 .name p a {
  color: #2d71c1;
  hyphens: auto;
  line-height: 22px;
  font-size: 20px;
}

.full-multi-pager .three-col-teaser-product-1 .next-btn.btn-bottom{
  bottom: 20px;
}

.afriso-sushi-full-width .teaser .next-btn {
  position: unset;
  left: unset;
  bottom: unset;
  display: block;
  margin: 10px auto;
}

.afriso-sushi-full-width .teaser {
  display: flex;
  flex-direction: column;
  min-height: unset !important;
  height: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}

.afriso-sushi-full-width .teaser .ezrichtext-field {
  padding: 0;
}

.afriso-sushi-full-width .teaser:before {
  display: none;
}

.afriso-sushi-full-width .teaser .teaser-content {
  flex: 1;
}

.product-section .product-finder-list-wrapper .three-col-img-text-teaser {
  margin-left: -15px;
  margin-right: -15px;
}

.afriso-border-grey .common_teaser {
  border: 1px solid lightgrey;
}

.teaser_title_img_intro_price {
  position: relative;
  margin-top: 30px;
  border: 1px solid lightgrey;
}
.teaser_title_img_intro_price .image-teaser, .teaser_title_img_intro_price .info, .teaser_title_img_intro_price .intro {
  float:left;
}

.teaser_title_img_intro_price .info, .teaser_title_img_intro_price .intro {
  margin-top:30px;
}

.teaser_title_img_intro_price .intro {
  width: 50%;
}

.teaser_title_img_intro_price .next-btn.btn-bottom,
.three-col-teaser-product-1 .next-btn.btn-bottom,
.three-col-img-text-teaser-2 .next-btn.btn-bottom{
  bottom: 6px;
}

.teaser-product-1 .next-btn.btn-bottom{
  bottom: 50px;
}

.afriso-benefits-teaser{
  padding: 15px;
  border: 1px solid #c4c4c4;
  margin-top: 15px;
  margin-bottom: 15px;
}

.afriso-benefits-teaser .afriso-benefits-teaser-text {
  width: 60%;
}

.afriso-benefits-teaser .afriso-benefits-teaser-text p{
  font-size: 14px;
}

.afriso-benefits-teaser figure{
  width: 30%;
  margin-right: 15px;
}

.afriso-faq-teaser .panel {
  border-radius: 0;
  border: 0;
}

.afriso-faq-teaser .panel-heading {
  background-color: #e4e4e4;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.afriso-faq-teaser .panel-title .more-less {
  float: right;
  color: #0065BF;
}

.afriso-faq-teaser .panel-title a {
  color: #0065BF;
  text-decoration: none;
  display: inline-block;
  width: 100%
}

.afriso-faq-teaser .panel-title a.collapsed i.glyphicon-chevron-up::before {
  content: "\e114";
}

.afriso-faq-teaser .panel-title a:hover, .afriso-faq-teaser .panel-title a:active {
  color: #0065BF;
  text-decoration: none;
}

.row-eq-height .afriso-benefits-teaser {
  flex: 1;
}

.ix-bgc-light-blue .three-col-img-text-teaser .same-height.border {
  padding: 0;
}

.ix-bgc-light-blue .three-col-img-text-teaser .same-height.border figure {
  min-height: unset;
}

.ix-bgc-light-blue .three-col-img-text-teaser .same-height.border figure img {
  position: unset;
  top: unset;
  left: unset;
  transform: none;
  margin-bottom: 25px;
}

.three-col-img-text-teaser {
  padding-top: 27px;
}

@media screen and (max-width: 768px) {
  .three-col-img-text-teaser {
    padding-top: 9px;
  }
}

.teaser-block > .next-btn {
  margin-bottom: 0 !important;
  bottom: 12px !important;
}