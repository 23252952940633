.productfinder-filter .caret, .productfinder-filter .dropup .caret {
  content: unset;
  border: unset;
}

.productfinder-filter .bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  right: auto;
}

.productfinder-filter .dropdown-toggle {
  border-radius: unset;
}

.productfinder-filter .btn {
  padding: 3px 8px 3px 8px;

}

.productfinder-filter .form-control {
  height: auto;
}

.reset-productfinder {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 10px;
  color: #fff;
  background-color: #006eb7;
  border-color: #006eb7;
  text-decoration: none;
  border-radius: 0;
}

.reset-productfinder:hover {
  font-size: 14px;
  line-height: 21px;
  color: #fff;
  background-color: #006eb7;
  border-color: #006eb7;
  text-decoration: none;
}

.productfinder-filter .bootstrap-select.btn-group .dropdown-menu {
  min-width: 200px;
}

.product-finder-list-wrapper .row .col-sm-3 .row, .product-finder-list-wrapper .row .col-sm-9 .row {
  margin-left: unset;
  margin-right: unset;
}

.product-finder-content {
  padding-bottom: 38px;
}

.product-finder-content h1 {
  font-size: 35px;
  font-family: Arial, sans-serif;
}

.product-finder-content p {
  margin-bottom: 21px;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0;
}

#accordion-info-product-variant .panel-default{
  border-bottom: none;
}
#accordion-info-product-variant h2{
  font-family: inherit;
  font-weight: normal;
}

.product-variant-panel-group .indented-table .table {
  table-layout: auto;
}

.product-variant-panel-group .indented-table .table .rk,
.product-variant-panel-group .indented-table .table .pg {
  width: 45px;
  text-align: center;
}

.product-variant-panel-group .indented-table .table .artnumber,
.product-variant-panel-group .indented-table .table .price,
.product-variant-panel-group .indented-table .table tr td:last-child, .product-variant-panel-group .indented-table .table tr th:last-child {
  width: 75px;
  text-align: center;
}

.download-center .productfinder-filter {
  margin-top: 30px;
}

.download-center .productfinder-filter .dropdown-menu .btn-close {
  display: none;
}

@media (max-width: 768px) {
  .reset-productfinder {
    float: none !important;
  }
}
