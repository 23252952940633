h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #0065BF;
}

h2, h3, h4, .h2, .h3, .h4 {
    font-weight: 500;
}

h3, h4, .h3, .h4 {
    text-transform: none;
}

h2, .h2 {
    font-size: 35px;
}

h4, .h4 {
    font-size: 20px;
}

.ezrichtext-field h1, .ezrichtext-field h2, .ezrichtext-field h3, .ezrichtext-field h4, .ezrichtext-field h5, .ezrichtext-field h6 {
    margin-bottom: 15px;
    padding-top: 0;
}

.ezrichtext-field h3 {
    line-height: 27px;
}

.ix-bgc-light-blue {
    background: #ebf5ff;
}

.ix-mb-10 {
    margin-bottom: 10px;
}

.ix-mb-15 {
    margin-bottom: 15px;
}

.ix-mb-30 {
    margin-bottom: 30px;
}

.ix-mb-45 {
    margin-bottom: 45px;
}

.ix-mb-60 {
    margin-bottom: 60px;
}

.ix-mb-9-i {
    margin-bottom: 9px !important;
}

.ix-mt-15 {
    margin-top: 15px;
}

.ix-mt-30 {
    margin-top: 30px;
}

.ix-mt-60 {
    margin-top: 60px;
}

.zone-post_header {
    margin-top: 0px;
}

.facet-property label {
    font-size: 12px;
}

.max-height-450 {
    max-height: 450px;
}

.content-block .article-content > h1,
.content-block .article-content > h2,
.content-block .article-content > h3,
.content-block .article-content > h4,
.content-block .article-content > h5,
.content-block .article-content > h6 {
    margin-bottom: 25px;
    font-family: Arial, sans-serif;
}

.content-block .article-content > .article-body > .ezrichtext-field > p {
    margin-top: 15px;
    margin-bottom: 15px;
}

.content-block .article-content > .article-body > .ezrichtext-field > div > .ix-float-left > .caption {
    margin-top: 5px;
}

.content-block .article-content > .article-body > .ezrichtext-field > div > .ix-float-left > .caption > .ezrichtext-field > p {
    font-size: 14px;
}

/*
 * ng_news
 */
.content-block .article-content > .article-date {
    margin-top: 10px;
}

.content-block .article-content > .article-footer {
    margin-top: 50px;
    margin-bottom: 50px;
}

.content-block .article-content > .article-footer > .newsletter-box > form {
    margin-top: 20px;
}

.flex-row-container .same-height {
    min-height: unset;
    height: calc(100% - 30px);
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
}

.flex-row-container a .ezrichtext-field {
    padding: 20px 0 10px !important;
}

.flex-row-container .next-btn.btn-bottom {
    position: relative;
    margin: 10px auto !important;
    transform: none;
    left: 0;
    bottom: 0 !important;
}

.flex-row-container a .ezrichtext-field p:last-of-type {
    margin: 0;
}

.ngl-block.h1, .ngl-block.h2{
    font-size: 2em;
    font-weight: 400;
    letter-spacing: 0;
    padding: 0.67em 0;
}

.ngl-block.h2{
    font-size: 1.9em;
}

.ngl-block.h3{
    font-size: 1.8em;
}

.ngl-block .ezrichtext-field {
    padding: 25px 0;
}

@media screen and (max-width: 992px) {
    .small-header > .search-wrapper {
        margin-top: 72px;
        margin-bottom: 10px;
        text-transform: none;
        font-weight: 500;
        line-height: normal;
    }
}

nav .pagination {
    float: right;
}

.ix-float-left {
    float: left;
    margin-right: 15px;
    margin-bottom: 5px;
}

.ix-clearfix {
    content: " ";
    clear: both;
}

.ix-mt-0 {
    margin-top: 0px !important;
}

.ix-mr-3 {
    margin-right: 3rem;
}

.ix-ml-3 {
    margin-left: 3rem;
}

.zone-right aside h3 {
    font-size: 20px;
    text-transform: uppercase;
    color: #0065BF;
}

.zone-right aside .top-img-bottom-text h4 {
    font-size: 15px;
    text-transform: uppercase;
}

.zone-right .list-row .list-item {
    padding: 8px 0;
}

.zone-right .list-row .list-item:not(:last-of-type) {
    border-bottom: 1px solid #e4e4e4;
}

.ngl-vt-list li:not(:last-of-type) {
    border-bottom: 1px solid #e4e4e4;
}

footer .bottom-footer .footer-elements .fire,
footer .bottom-footer .footer-elements .earth,
footer .bottom-footer .footer-elements .air,
footer .bottom-footer .footer-elements .water {
    margin-top: 8px;
    width: 34px;
    height: 43px;
    float: left;
    margin-right: 14px;
}

.footer_rich_text .ezrichtext-field{
    padding: 0;
}

@media screen and (max-width: 416px) {
    .content h1 {
        font-size: 30px;
    }
}

.header .language-wrapper {
    display: none;
    position: relative;
    left: 44px;
    z-index: 9;
    float: left;
}

.header .language-wrapper li {
    display: inline-block;
}

.header .language-wrapper a {
    display: inline-block;
    width: 34px;
    height: 34px;
    position: relative;
}

.header .language-wrapper a span {
    color: #2d71c1;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
}

.header .language-wrapper a.language span {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 10px;
    line-height: 15px;
}

@media screen and (max-width: 992px) {
    .header .language-wrapper {
        left: 69px;
    }
}

@media screen and (max-width: 480px) {
    .header .language-wrapper {
        left: 28%;
    }
}

.partner-search .next-btn {
    margin-top: -5px;
}

.zone-right a {
    text-decoration: none;
}

.zone-right a:hover {
    text-decoration: underline;
}

.zone-right .content h1, h2, h3 {
    margin-bottom: 4px;
}

.customer-filter .selected-values span {
    background: unset;
    padding: 0 5px 0 5px;
    margin-bottom: 10px;
    display: inline-block;
}

.customer-filter .selected-values span a {
    height: unset;
    text-decoration: none;
}

.customer-filter .selected-values span a:before {
    content: 'x';
    font-size: 14px;
    color: #b6101d;
    padding: 0 5px;
}

.pagination .current, .page {
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
}

.pagination .page a {
    text-decoration: none;
}

.form-group {
    margin-bottom: unset;
}

.filter-form .form-group:first-child {
    margin-left: 0px;
}

.result-list.tab-content .pagination .next {
    top: inherit;
    margin-top: -19px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .result-list.tab-content .pagination .next {
        margin-top: 4px;
    }
}

@supports (-ms-ime-align:auto) {
    .result-list.tab-content .pagination .next {
        margin-top: 4px;
    }
}

@media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) {

        .result-list.tab-content .pagination .next {
            margin-top: 4px;
        }
    }
}

.result-list.tab-content .pagination .prev {
    top: inherit;
    margin-top: 4px;
}

.result-list h3 {
    hyphens: auto;
    margin-top: 30px;
    padding-left: 15px;
}

.download-center input[type="radio"] {
    visibility: hidden;
    display: none;
}

.download-center input[type="radio"] + label {
    border-radius: 0;
    border: none;
    font-size: 20px;
    padding: 0 14.45px;
    min-width: 120px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    margin-right: 2px;
    font-family: Arial, sans-serif;
    font-weight: unset;
    background: #c4c4c4;
    color: #fff;
}

.download-center input[type="radio"]:checked + label {
    color: #2d71c1;
    background-color: #e4eef8;
}

.download-center input[type=text] {
    height: 30px;
    border: 1px solid #c4c4c4;
    font-size: 14px;
    width: 100%;
    outline: none;
}

.download-center input[type=text]::placeholder {
    color: #c4c4c4;
    opacity: 1;
}

.download-center input[type=text]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #c4c4c4;
}

.download-center input[type=text]::-ms-input-placeholder { /* Microsoft Edge */
    color: #c4c4c4;
}

.download-center .selected-values {
    padding: 10px;
    background-color: #e4e4e4;
    margin-top: 15px;
}

.download-center .btn-default {
    background-color: #e6e6e6;
}

.pull-ten-px-right {
    margin-left: 10px;
}

@media (min-width: 992px) {
    .col-md-4:nth-child(3n+1) {
        clear: left;
    }
}

.search-page .three-col-tabs-content .nav-tabs {
    margin-left: 0px;
}

.printversion {
    visibility: hidden;
}

@media print {
    .nav, .icon-nav, .table .col4, .table .col5, .cart, .language, .language-variant, .send-inquiry, .bottom-footer-section-1, .bottom-footer-section-2, .sf-toolbarreset {
        visibility: hidden;
    }

    a[href]:after {
        content: none !important;
    }

    .printversion {
        visibility: visible;
        position: absolute;
        bottom: 300px;
    }
}

.partner-search:lang(en-GB) .container:nth-child(2) form[name=ix_search]:nth-child(1) {
    display: none;
}

.ngl-full_view h1 {
    top: 0;
}

.product-manual-download-select {
    min-width: 150px;
}

.product-manual-download-select select{
    padding: 0 8px;
    font-weight: bold;
    font-size: 12px;
    color: #687074;
}

.product-manual-download-title {
    font-weight: bold;
    font-size: 12px;
    color: #687074;
}

.ngl-full_view .accordion .panel-default .panel-heading a {
    color: #0065BF;
}

.ngl-full_view ul.square-list li{
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.square-list li {
    font-size: 16px !important;
    margin-bottom: 0 !important;
    line-height: 19px !important;
    color: #687074 !important;
}

.product-pictograms {
    margin-top: 25px;
}

.product-pictograms img {
    margin-right: 10px;
}

.thumbnail-video-full i {
    width: 186px;
    height: 136px;
    position: absolute;
    background-image: url('../images/slide-play.png');
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.7;
    z-index: 1;
    top: 35%;
    left: 25%;
    background-size: 186px;
}

sup {
    top: -0.5em;
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

.pagination {
    display: inline-flex;
    align-items:center;
    float: right;
    margin: unset;
}

.pagination .prev,
.pagination .next {
    position: unset;
    top: 0;
    margin: 3px !important;
}

.pagination .current,
.pagination .page {
    margin: 0 5px;
}

[name=underline] {
    text-decoration: underline;
}

.langMenuFix {
    left: 1px;
}

.flex-row-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.flex-row-container > .flex-row-column {
    flex: 1;
    text-align: center;
    padding: auto;
    max-width: calc(33.33%)
}

.img-container img {
    width: 100%;
}

.facet-picker .dropdown-menu .popover-title { display: none; }
.facet-picker .dropdown-menu .dropdown-menu.inner { padding-top: 5px !important; }

.heading {
    font-weight: bold;
}

.ul-big-square li {
    list-style: none;
    padding-left: 20px;
}

.ul-big-square li:before {
    content: '■';
    font-size: 18px;
    line-height: 14px;
    margin-left: -15px;
}

tr.border_top td {
    border-top: 1px solid #e4eef8;
}

.flex-end {
    display: flex;
    align-items: flex-end;
}

@media screen and (min-width: 993px) {
    .ezrichtext-field ul li,
    .square-list li {
        line-height: inherit;
        margin-bottom: 5px !important;
    }
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

@media screen and (max-width: 768px) {
    .mt-m-25 {
        margin-top: 25px !important;
    }

    .mt-m-50 {
        margin-top: 50px !important;
    }

    .mt-m-0 {
        margin-top: 0px !important;
    }

    .mb-m-25 {
        margin-bottom: 25px !important;
    }

    .mb-m-50 {
        margin-bottom: 50px !important;
    }

    .mb-m-0 {
        margin-bottom: 0px !important;
    }

    .pt-m-25 {
        padding-top: 25px !important;
    }

    .pt-m-50 {
        padding-top: 50px !important;
    }

    .pt-m-0 {
        padding-top: 0px !important;
    }

    .pb-m-25 {
        padding-bottom: 25px !important;
    }

    .pb-m-50 {
        padding-bottom: 50px !important;
    }

    .pb-m-0 {
        padding-bottom: 0px !important;
    }
}

[name="sub"] {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    bottom: -0.25em;
}

.nestedDefaultRow {
    display: flex;
    align-items: flex-start;
}

.heightGetter {
    position: relative;
}

.heightGetter > div {
    position: absolute;
    visibility: hidden;
    display: block;
    width: 100%;
}

.drawing-height-60 {
    max-height: 60vh;
    width: auto !important;
}

.no-padding-top {
    padding-top: 0;
}

.no-padding-top .container {
    padding-top: 0;
}

.no-margin-top {
    margin-top: 0;
}

.row-eq-height,
.row-eq-height [class*="col"] {
    display: flex;
    flex-wrap: wrap;
}

.min-height-pct-90 div {
    min-height: 90%;
}

.width-pct-100 {
    width: 100% !important;
}

.no-padding {
    padding: 0 !important;
}

.to-top-button {
    position: fixed;
    bottom: 18px;
    transform: translateY(-50%);
    z-index: 9;
    right: 4px;
}

@media screen and (max-width: 1100px) {
    .to-top-button {
        right: 1vw;
    }
}

.caption > .ezrichtext-field {
    padding-top: 0;
}

.no-border {
    border: unset !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.pb-0-children * {
    padding-bottom: 0 !important;
}

.pt-0-children * {
    padding-top: 0 !important;
}

.same-height .border {
    padding: 31px 5px 10px !important;
}

[name=sup] {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: super;
}

.filter-form .form-group {
    float: left !important;
    margin-left: 5px !important;
}

.customer-filter .dropdown-menu .btn-close {
    margin: 6px;
    background: none;
    border: 2px solid #cccccc;
    font-weight: bold;
    font-family: 'NettoPro-Bold', Arial, sans-serif;
    display: block;
    text-align: center;
    color: #000;
}

.ps-scrollbar-x-rail {
    display: none !important;
}

.accordion .panel-default .panel-heading a {
    color: #0065BF;
}

.ezrichtext-field table {
    width: unset !important;
    min-width: 85% !important;
}

.no-pading-top-imp {
    padding-top: 0 !important;
}

.bold-font {
    font-weight: bold;
}
