

/*Footer Layout*/
footer .footer_title {
  font-size: 15px;
  color: #7e8383;
  text-transform: uppercase;
  margin-bottom: 22px;
  padding-top: 22px;
}

.zone-footer {
  background: #c4c4c4;
}

.zone-footer a, .zone-footer .footer_rich_text p {
  color: #ffffff;
  font-size: 14px;
}

.zone-footer a.footer_link {
  color: #7e8383;
}

.zone-footer a:hover {
  color: #0065BF;
}

.zone-footer .list-item {
  margin-bottom: 13px;
}

.zone-footer .footer_link {
  margin-bottom: 13px;
}

.zone-footer .footer_products .list-item {
  margin-bottom: 11px;
}

.zone-footer .list-item .image img {
  display: none;
}

.zone-footer .footer_products .list-item p {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px;
}

.zone-footer .same-height.border.image {
  text-align: left;
  margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
  footer {
    padding-bottom: 10px
  }
}

footer .top-footer {
  background: #c4c4c4;
  padding: 24px 0 56px
}

@media screen and (max-width: 767px) {
  footer .top-footer ul {
    margin-bottom: 40px
  }
}

@media screen and (max-width: 767px) {
  footer .top-footer ul li {
    line-height: 30px
  }
}

footer .top-footer ul li:first-child {
  font-size: 15px;
  color: #7e8383;
  text-transform: uppercase;
  margin-bottom: 22px
}

footer .top-footer ul li a {
  font-size: 14px;
  color: #fff;
  display: block
}

footer .top-footer ul.program li a {
  margin-bottom: 5px
}

footer .top-footer ul.training li span {
  margin-bottom: 10px;
  display: block;
  font-size: 12px;
  line-height: 19.49px;
  color: #7e8383
}

footer .top-footer ul.career li a {
  margin-bottom: 10px
}

footer .top-footer ul.contact {
  margin-bottom: 30px
}

footer .top-footer ul.contact li {
  font-size: 14px;
  color: #fff
}

footer .top-footer ul.contact li:first-child {
  margin-bottom: 28px;
  color: #7e8383
}

footer .top-footer p {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0
}

footer .top-footer p:before, footer .top-footer p:after {
  content: " ";
  display: table
}

footer .top-footer p:after {
  clear: both
}

footer .top-footer p span {
  float: left;
  min-width: 51px
}

footer .bottom-footer {
  background: #fff;
  padding: 0px 0px 3px 0px;
}

footer .bottom-footer p {
  margin-bottom: 0;
  font-size: 12px;
  color: #7e8383;
  line-height: 32px
}

@media screen and (max-width: 767px) {
  footer .bottom-footer p {
    margin-bottom: 20px
  }
}

footer .bottom-footer p a {
  display: inline-block;
  font-size: 12px;
  color: #7e8383;
  line-height: 32px;
  margin-left: 6px
}

footer .bottom-footer .bottom-footer-section-1 {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #e4e4e4;
  color: #949494;
}

footer .bottom-footer .bottom-footer-section-1 .footer-slogan .slogan-row-1 {
  font-size: 24px;
}

footer .bottom-footer .bottom-footer-section-1 .footer-slogan .slogan-row-2 {
  display: block;
  margin-left: 170px;
  font-size: 18px;
  line-height: 18px;
}

footer .bottom-footer .bottom-footer-section-2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (max-width: 767px) {
  footer .bottom-footer .bottom-footer-section-1 .footer-slogan {
    text-align: center;
  }
}

footer .bottom-footer .footer-elements:before, footer .bottom-footer .footer-elements:after {
  content: " ";
  display: table
}

footer .bottom-footer .footer-elements:after {
  clear: both
}

@media screen and (max-width: 767px) {
  footer .bottom-footer .footer-elements {
    width: 212px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px
  }
}

footer .bottom-footer .footer-elements .fire, footer .bottom-footer .footer-elements .earth, footer .bottom-footer .footer-elements .air, footer .bottom-footer .footer-elements .water {

  margin-top: 8px;
  width: 34px;
  height: 38px;
  float: left;
  margin-right: 14px
}

footer .bottom-footer .footer-elements .fire:hover, footer .bottom-footer .footer-elements .earth:hover, footer .bottom-footer .footer-elements .air:hover, footer .bottom-footer .footer-elements .water:hover {
  text-decoration: none
}

footer .bottom-footer .footer-elements .fire {
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -105px 0;
  margin-left: 20px
}

footer .bottom-footer .footer-elements .water {
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -70px 0
}

footer .bottom-footer .footer-elements .earth {
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat 0 0
}

footer .bottom-footer .footer-elements .air {
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -35px 0
}

footer .bottom-footer .info-social:before, footer .bottom-footer .info-social:after {
  content: " ";
  display: table
}

footer .bottom-footer .info-social:after {
  clear: both
}

@media screen and (max-width: 767px) {
  footer .bottom-footer .info-social {
    width: 206px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 992px) {
  .info-social {
    float: right;
  }
}

footer .bottom-footer .info-social a {
  display: block;
  float: left;
  margin-right: 9px;
  width: 34px;
  height: 34px
}

footer .bottom-footer .info-social a:hover {
  cursor: pointer
}

footer .bottom-footer .info-social a:last-child {
  margin-right: 0
}

footer .bottom-footer .info-social a.facebook {
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -35px -101px
}

footer .bottom-footer .info-social a.facebook:hover {
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat 0 -101px
}

footer .bottom-footer .info-social a.twitter {
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -105px -101px
}

.info-social > a {
  transition: none !important;
}

footer .bottom-footer .info-social a.twitter:hover {
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -70px -101px;
}

footer .bottom-footer .info-social a.youtube {
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -245px -101px
}

footer .bottom-footer .info-social a.youtube:hover {
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -210px -101px
}

footer .bottom-footer .info-social a.vk {
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -175px -101px
}

footer .bottom-footer .info-social a.vk:hover {
  background: url(../images/bgi/AFRISO_Icons_Sprite.png) no-repeat -140px -101px
}

footer .bottom-footer .info-social a.instagram {
  background: url(../images/bgi/InstaLg.png) no-repeat;
  background-size: contain;
}

footer .bottom-footer .info-social a.instagram:hover {
  background: url(../images/bgi/InstaDg.png) no-repeat;
  background-size: contain;
}

footer .bottom-footer .info-social a.linkedin {
  background: url(../images/bgi/linkenLg.png) no-repeat;
  background-size: contain;
}

footer .bottom-footer .info-social a.linkedin:hover {
  background: url(../images/bgi/linkenDg.png) no-repeat;
  background-size: contain;
}

@media (max-width: 465px) {
  footer .bottom-footer p, footer .bottom-footer p a {
    font-size: 10px;
  }
}

@media (max-width: 375px) {
  footer .bottom-footer p, footer .bottom-footer p a {
    font-size: 8px;
  }
}

@media (max-width: 320px) {
  footer .bottom-footer p, footer .bottom-footer p a {
    font-size: 7px;
  }
}
