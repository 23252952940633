// Typography style file
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color:$linkColor;
    transition:background 150ms, color 150ms;
    &,
    &:hover,
    &:focus {
        outline:none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
}

p {
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    color:hsl(220, 2, 35);
    margin:0 0 1em;
}
/*
.ngl-vt-title{
    text-align:center;
    .title {
       margin:0;
    }
}
*/
.section-title,
.section-gray-title {
    border-bottom:1px solid hsl(0, 0, 80);
    padding-bottom:0 !important;
    .title{
        display:inline-block;
        color:$mainColor;
        text-transform:uppercase;
        border-bottom:5px solid $mainColor;
        margin:0 0 -1px 0;
        padding-bottom:20px;
    }
}

.section-gray-title {
    .title {
        border-color:#999;
        font-size:21px;
    }
}
