.search-info, .tech-data-heading {
  font-weight: bold;
  color: #0065BF;
}

.tech-data-img {
  border: 1px solid #bbbbbb;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  margin-top: 10px;
}

.tech-data-img img {
  position: absolute;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
}

.tech-data-popup {
  background-color: #f9f9f9;
  display: none;
}

.tech-data-popup td {
  text-align: center;
}

.tech-data-popup tr.border_top:first-child {
  height: 30px;
}

.tech-data-popup table .border_top td {
  border-color: #bbbbbb;
}

.tech-data-popup tr.border_top:first-of-type td {
  border: none;
}

.tech-data-popup img {
  width: 100%;
  background: white;
  padding: 10px;
  margin-bottom: 20px;
}

.panel-body-technical-data {
  width: 100%;
  display: inline-block;
}

.panel-body-technical-data > div {
  padding: 15px;
}

@media (min-width: 769px) {
  .panel-body-technical-data > div {
    float: left;
    width: 50%;
    color: #687074;
  }
}

.tech-drawings-tab {
  float: none !important;
}
