.slider-mb-1 {
    max-width: 360px;
    overflow: hidden;
}

.slider-mb-3 {
    max-width: 750px;
    overflow: hidden;
}

.slider-mb-1 figure {
    height: 300px;
    overflow: hidden;
}

.slider-mb-3 figure {
    height: 300px;
    overflow: hidden;
}

.afriso-simple-slider-image {
    padding: 25px 0;
}

.slider-wrapper .slider-for .slick-list {
    height: 360px;
}

.slider-wrapper .slider-for .slick-list iframe {
    border-width: 0;
}

.afriso-sushi-full-width .carousel-inner {
    width: calc(100% - 50px) !important;
}

.afriso-sushi-full-width .carousel-control {
    width: 45px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.afriso-sushi-full-width .carousel-control span {
    margin: 0 !important;
}

.afriso-sushi-full-width .teaser .next-btn {
    position: unset;
    left: unset;
    bottom: unset;
    display: block;
    margin: 10px auto;
}

.afriso-sushi-full-width .carousel-control.left {
    left: 0;
}

.afriso-sushi-full-width .carousel-control.right {
    right: 0;
}

.afriso-sushi-full-width .teaser {
    display: flex;
    flex-direction: column;
    min-height: unset !important;
    height: 100%;
    padding: 0 5px;
    box-sizing: border-box;
}

.afriso-sushi-full-width .teaser .ezrichtext-field {
    padding: 0;
}

.afriso-sushi-full-width .teaser:before {
    display: none;
}

.afriso-sushi-full-width .teaser .teaser-content {
    flex: 1;
}

.afriso-sushi-full-width .slick-track {
    display: flex;
    flex-direction: row;
}

.afriso-sushi-full-width .slick-slide {
    height: unset;
}

.afriso-sushi-full-width .slick-slide > div,
.afriso-sushi-full-width .slick-slide > div > .item {
    height: 100%;
}

.slider-left-text-right ul.square-list li:before {
    line-height: 22px;
}

.afriso-comment-carousel {
    margin-top: 25px;
    margin-bottom: 25px;
}

.comment-carousel .carousel-inner .item div:first-child {
    max-width: 450px;
    margin:0 auto;
}

.afriso-bg-grey .comment-carousel .carousel-inner .item {
    background-color: #e4e4e4;
}

.comment-carousel .carousel-inner .item.active,
.comment-carousel .carousel-inner .item-next,
.comment-carousel .carousel-inner .item-prev {
    display: block;
}

.comment-carousel .glyphicon {
    color: #0065BF;
}
.afriso-sushi-bar {
    margin-top: 25px;
    margin-bottom: 25px;
    position: relative;
}

.afriso-sushi-bar .carousel-inner { width: 85%; margin:0 auto;}
.afriso-sushi-bar .carousel-inner .active.left  { left: -25%;             }
.afriso-sushi-bar .carousel-inner .active.right { left: 25%;              }
.afriso-sushi-bar .carousel-inner .next         { left: 25%               }
.afriso-sushi-bar .carousel-inner .prev         { left: -25%              }
.afriso-sushi-bar .carousel-control.left, .afriso-comment-carousel .carousel-control.left  { background-image: none; }
.afriso-sushi-bar .carousel-control.right, .afriso-comment-carousel .carousel-control.right { background-image: none; }
.afriso-sushi-bar .carousel-inner .item         { background: white; position: relative;     }

.afriso-sushi-bar .carousel-inner > .item.next,
.afriso-sushi-bar .carousel-inner > .item.active.right {
    left: 0;
    -webkit-transform: translate3d(25%, 0, 0);
    -ms-transform: translate3d(25%, 0, 0);
    -o-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0);
}

.afriso-sushi-bar .carousel-inner > .item.prev,
.afriso-sushi-bar .carousel-inner > .item.active.left {
    left: 0;
    -webkit-transform: translate3d(-25%, 0, 0);
    -ms-transform: translate3d(-25%, 0, 0);
    -o-transform: translate3d(-25%, 0, 0);
    transform: translate3d(-25%, 0, 0);
}

.afriso-sushi-bar .item .same-height{
    min-height: 200px;
}

.afriso-sushi-bar .item p{
    text-align: center;
    margin-top: 15px;
}

.afriso-sushi-bar .carousel-control span{
    margin-top: 30%;
}

.carousel-inner-sushi-bar {
    width: 97% !important;
    right: 2% !important;
}

.sushi-bar-arrow {
    margin-top: 9vh !important;
    width: 2% !important;
}

.sushi-bar-arrow-right {
    right: 2% !important;
}

.sushi-bar-arrow-left {
    left: -1% !important;
}

.animation-3d-main-gallery {
    height: 33vh !important;
}
.animation-3d-main-gallery .product-slide-preview {
    width: 95% !important;
    height: 90% !important;
}

.mr-vw-45 {
    margin-right: 45vw;
}
