$afrisoButtonColor: #0065BF;

.afriso-btn-blue {
    display: inline-block !important;
    background: $afrisoButtonColor;
    color: #ffffff !important;
    letter-spacing: 1px;
    padding: 10px 45px;
    text-align: center;
    font-size: 16px !important;
    font-weight: bold;
    text-decoration:none !important;
    margin-bottom: 18px !important;
    margin-top: 18px !important;

    &:hover {
        background: darken($afrisoButtonColor, 10%);
        background-color:#005CAD;
    }
}

.afriso-blue {
    color: #0065BF !important;
}

.btn.afriso-blue {
    color: #ffffff !important;
    background-color: #0065BF !important;
    border: none !important;
}

.btn.afriso-blue:hover {
    background-color: #7e8383 !important;
}

.full-table-font {
    color: #687074;
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

.afriso-bg-blue {
    background-color: #ebf5ff;
}

.afriso-bg-grey {
    background-color: #e4e4e4;
}

.afriso-border-grey .common_teaser {
    border: 1px solid lightgrey;
}

.afriso-bg-grey div[class*="col-"] {
    min-height: unset;
}

.afriso-underline-white {
    color: #fff !important;
    text-decoration: underline !important;
}

.bootstrap-select.btn-group .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 8px;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
    margin-right: 5px;
}

.bootstrap-select.btn-group .dropdown-toggle .bs-caret {
    display: block;
}

.bootstrap-select.btn-group .dropdown-toggle .bs-caret .caret {
    position: unset;
}

.afriso-list-product {
    border: 1px solid #c4c4c4;
    margin-bottom: 25px;
}

.afriso-list-product .name{
    margin-top: 25px;
}

.afriso-list-product img{
    margin-top: 25px;
}

.afriso-list-product .description{
    float:left;
    width: 70%;
    margin-top: 25px;
}

.afriso-list-product .next-btn{
    right: 5%;
    bottom: 50%;
    left: auto;
}

.footer-col-1 {
    font-size: 12px;
    color: #7e8383 !important;
}

.footer-col-1 .ezobjectrelationlist-field {
    margin-left: 4px;
    display: inline-flex !important;
}

.footer-col-1 a {
    padding-left: 6px;
    padding-right: 6px;
    border-left: 1px solid #7e8383;
    font-size: 12px;
    text-transform: uppercase;
    color: #7e8383;
 }

.download_list li {
    padding-top: 6px;
    padding-bottom: 6px;
}

.header-icon img {
    height: 100%;
    margin-right: 1rem;
}

.header-icon a {
    width: 45px;
}

.header-icon svg {
    height: 100%;
}

.header-icon {
    height: 45px;
    display: flex;
    margin: 0 0 0 6rem;
    font-size: 60%;
}

@media screen and (max-width: 700px) {
    .header-icon img {
        margin-right: 0;
    }

    .header-icon svg {
        margin-left: 12px;
    }

    .header-icon {
        margin: 0;
    }
}

@media screen and (min-width: 995px) and (max-width: 1200px) {
    .header-icon {
        margin: 0;
    }
}
@media screen and (min-width: 1200px) {
    .header-icon {
        margin-left: 6rem;
    }
}

.solar-iframe iframe {
    width: 100%;
    min-height: 440px;
    border: none;
}

.product-lightbox-text {
    font-size: 16px;
    font-weight: bold;
    color: #687074;
    margin-bottom: 3px;
}
